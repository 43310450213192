<template>
  <v-app>
    <v-main>
      <v-container>
        <div class="bg-grey-lighten-5 pa-3 rounded mb-2">
          <h1 style="font-size: 25px; font-weight: bold">
            Whitelabel AI Helper 🧠
          </h1>
          <p class="my-2 px-1">
            First input some basic data for the white label, then complete the
            second form, using the prefilled data, editing it where necessary.
          </p>
          <v-form @submit.prevent="submitGptForm">
            <v-text-field
              v-model="gptData.storeName"
              label="Company Name"
              required
              :rules="[(v) => !!v || 'Company Name is required']"
            />
            <v-text-field
              v-model="gptData.storeAddress"
              label="Address"
              required
              :rules="[(v) => !!v || 'Address is required']"
            />
            <v-text-field
              v-model="gptData.storeType"
              label="Store Type"
              placeholder="Convenience Store"
              required
              :rules="[(v) => !!v || 'Store Type is required']"
            />
            <v-text-field
              v-model="gptData.openingHours"
              label="Opening Hours"
              placeholder="Use human language, e.g. 9-6 Everyday"
              required
              :rules="[(v) => !!v || 'Opening Hours are required']"
            />
            <v-text-field
              v-model="gptData.contactPhoneNumber"
              label="Contact Phone Number"
              required
              :rules="[(v) => !!v || 'Contact Phone Number is required']"
            />
            <v-btn
              :loading="gptData.gptLoading"
              type="submit"
              color="primary"
              prepend-icon="mdi-brain"
              :disabled="gptFormDisabled"
            >
              Generate White Label Data
            </v-btn>
            <span v-if="gptData.gptLoading" class="ml-2">
              {{ progressMessage }}
            </span>
          </v-form>
        </div>

        <v-form v-if="showMainForm" @submit.prevent="submitForm">
          <v-container>
            <h3 style="font-size: 20px; font-weight: 700; margin: 10px">
              Home Page
            </h3>

            <v-text-field
              v-model="home.title"
              label="Home Title"
              required
              :rules="[(v) => !!v || 'Home Title is required']"
            />

            <v-text-field
              v-model="home.seo.title"
              label="Home SEO Title"
              required
              :rules="[(v) => !!v || 'Home SEO Title is required']"
            />
            <v-textarea
              v-model="home.content"
              label="Home Main Content"
              required
              :rules="[(v) => !!v || 'Home Main Content is required']"
            />
            <v-textarea
              v-model="home.seo.description"
              label="Home SEO Description"
              required
              :rules="[(v) => !!v || 'Home SEO Description is required']"
            />
            <v-textarea
              v-model="home.seo.keywords"
              label="Home SEO Keywords"
              required
              :rules="[(v) => !!v || 'Home SEO Keywords are required']"
            />
          </v-container>
          <v-container>
            <h3 style="font-size: 20px; font-weight: 700; margin: 10px">
              Contact Page
            </h3>
            <v-text-field
              v-model="contact.title"
              label="Contact Title"
              required
              :rules="[(v) => !!v || 'Contact Title is required']"
            />
            <v-text-field
              v-model="contact.subtitle"
              label="Contact Subtitle"
              required
              :rules="[(v) => !!v || 'Contact Subtitle is required']"
            />
            <v-text-field
              v-model="contact.seo.title"
              label="Contact SEO Title"
              required
              :rules="[(v) => !!v || 'Contact SEO Title is required']"
            />
            <v-textarea
              v-model="contact.seo.description"
              label="Contact SEO Description"
              required
              :rules="[(v) => !!v || 'Contact SEO Description is required']"
            />
            <v-textarea
              v-model="contact.seo.keywords"
              label="Contact SEO Keywords"
              required
              :rules="[(v) => !!v || 'Contact SEO Keywords are required']"
            />
          </v-container>

          <v-container>
            <h3 style="font-size: 20px; font-weight: 700; margin: 10px">
              FAQ Page
            </h3>
            <v-text-field
              v-model="faq.title"
              label="FAQ Title"
              required
              :rules="[(v) => !!v || 'FAQ Title is required']"
            />
            <v-text-field
              v-model="faq.subtitle"
              label="FAQ Subtitle"
              required
              :rules="[(v) => !!v || 'FAQ Subtitle is required']"
            />
            <v-text-field
              v-model="faq.seo.title"
              label="FAQ SEO Title"
              required
              :rules="[(v) => !!v || 'FAQ SEO Title is required']"
            />
            <v-textarea
              v-model="faq.seo.description"
              label="FAQ SEO Description"
              required
              :rules="[(v) => !!v || 'FAQ SEO Description is required']"
            />
            <v-textarea
              v-model="faq.seo.keywords"
              label="FAQ SEO Keywords"
              required
              :rules="[(v) => !!v || 'FAQ SEO Keywords are required']"
            />
          </v-container>

          <v-container>
            <h3 style="font-size: 20px; font-weight: 700; margin: 10px">
              Forgot Password
            </h3>
            <v-text-field
              v-model="forgotPassword.title"
              label="Forgot Password Title"
              required
              :rules="[(v) => !!v || 'Forgot Password Title is required']"
            />
            <v-text-field
              v-model="forgotPassword.subtitle"
              label="Forgot Password Subtitle"
              required
              :rules="[(v) => !!v || 'Forgot Password Subtitle is required']"
            />
            <v-text-field
              v-model="forgotPassword.seo.title"
              label="Forgot Password SEO Title"
              required
              :rules="[(v) => !!v || 'Forgot Password SEO Title is required']"
            />
            <v-textarea
              v-model="forgotPassword.seo.description"
              label="Forgot Password SEO Description"
              required
              :rules="[
                (v) => !!v || 'Forgot Password SEO Description is required',
              ]"
            />
            <v-textarea
              v-model="forgotPassword.seo.keywords"
              label="Forgot Password SEO Keywords"
              required
              :rules="[
                (v) => !!v || 'Forgot Password SEO Keywords are required',
              ]"
            />
          </v-container>
          <v-container>
            <h3 style="font-size: 20px; font-weight: 700; margin: 10px">
              Login Page
            </h3>
            <v-text-field v-model="login.title" label="Login Title" required />
            <v-text-field
              v-model="login.subtitle"
              label="Login Subtitle"
              required
              :rules="[(v) => !!v || 'Login Subtitle is required']"
            />
            <v-text-field
              v-model="login.seo.title"
              label="Login SEO Title"
              required
              :rules="[(v) => !!v || 'Login SEO Title is required']"
            />
            <v-textarea
              v-model="login.seo.description"
              label="Login SEO Description"
              required
              :rules="[(v) => !!v || 'Login SEO Description is required']"
            />
            <v-textarea
              v-model="login.seo.keywords"
              label="Login SEO Keywords"
              required
              :rules="[(v) => !!v || 'Login SEO Keywords are required']"
            />
          </v-container>
          <v-container>
            <h3 style="font-size: 20px; font-weight: 700; margin: 10px">
              Register Page
            </h3>

            <v-text-field
              v-model="register.title"
              label="Register Title"
              required
              :rules="[(v) => !!v || 'Register Title is required']"
            />
            <v-text-field
              v-model="register.subtitle"
              label="Register Subtitle"
              required
              :rules="[(v) => !!v || 'Register Subtitle is required']"
            />
            <v-text-field
              v-model="register.seo.title"
              label="Register SEO Title"
              required
              :rules="[(v) => !!v || 'Register SEO Title is required']"
            />
            <v-textarea
              v-model="register.seo.description"
              label="Register SEO Description"
              required
              :rules="[(v) => !!v || 'Register SEO Description is required']"
            />
            <v-textarea
              v-model="register.seo.keywords"
              label="Register SEO Keywords"
              required
              :rules="[(v) => !!v || 'Register SEO Keywords are required']"
            />
          </v-container>
          <v-container>
            <h3 style="font-size: 20px; font-weight: 700; margin: 10px">
              Reset Password Page
            </h3>
            <v-text-field
              v-model="resetPassword.title"
              label="Reset Password Title"
              required
              :rules="[(v) => !!v || 'Reset Password Title is required']"
            />
            <v-text-field
              v-model="resetPassword.subtitle"
              label="Reset Password Subtitle"
              required
              :rules="[(v) => !!v || 'Reset Password Subtitle is required']"
            />
            <v-text-field
              v-model="resetPassword.seo.title"
              label="Reset Password SEO Title"
              required
              :rules="[(v) => !!v || 'Reset Password SEO Title is required']"
            />
            <v-textarea
              v-model="resetPassword.seo.description"
              label="Reset Password SEO Description"
              required
              :rules="[
                (v) => !!v || 'Reset Password SEO Description is required',
              ]"
            />
            <v-textarea
              v-model="resetPassword.seo.keywords"
              label="Reset Password SEO Keywords"
              required
              :rules="[
                (v) => !!v || 'Reset Password SEO Keywords are required',
              ]"
            />
          </v-container>
          <v-container>
            <h3 style="font-size: 20px; font-weight: 700; margin: 10px">
              About Us Page
            </h3>
            <v-text-field
              v-model="about.title"
              label="About Us Title"
              required
              :rules="[(v) => !!v || 'About Us Title is required']"
            />
            <v-text-field
              v-model="about.subtitle"
              label="About Us Subtitle"
              required
              :rules="[(v) => !!v || 'About Us Subtitle is required']"
            />
            <v-textarea
              v-model="about.about"
              label="About Us Content"
              required
              :rules="[(v) => !!v || 'About Us Content is required']"
            />
            <v-text-field
              v-model="about.seo.title"
              label="About Us SEO Title"
              required
              :rules="[(v) => !!v || 'About Us SEO Title is required']"
            />
            <v-textarea
              v-model="about.seo.description"
              label="About Us SEO Description"
              required
              :rules="[(v) => !!v || 'About Us SEO Description is required']"
            />
            <v-textarea
              v-model="about.seo.keywords"
              label="About Us SEO Keywords"
              required
              :rules="[(v) => !!v || 'About Us SEO Keywords are required']"
            />
          </v-container>

          <v-container>
            <div class="bg-grey-lighten-5 pa-3 rounded mb-2">
              <h3 style="font-size: 20px; font-weight: 700">Colours</h3>
              <p>
                Colours used throughout the site
                <span style="color: red">
                  <small>* Required</small>
                </span>
              </p>
              <br />

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="brandingColors.primary"
                    label="Primary Color"
                    prepend-icon="mdi-palette"
                    placeholder="#FFFFFF"
                    :rules="[
                      (v) => !!v || 'Primary Color is required',
                      (v) => !/\s/.test(v) || 'No spaces allowed',
                      (v) => /^#/.test(v) || 'Must start with a hashtag (#)',
                    ]"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="brandingColors.secondary"
                    label="Secondary Color"
                    prepend-icon="mdi-palette"
                    placeholder="#FFFFFF"
                    :rules="[
                      (v) => !!v || 'Secondary Color is required',
                      (v) => !/\s/.test(v) || 'No spaces allowed',
                      (v) => /^#/.test(v) || 'Must start with a hashtag (#)',
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="navbarColor"
                    label="Navbar Color"
                    prepend-icon="mdi-palette"
                    placeholder="#FFFFFF"
                    :rules="[
                      (v) => !!v || 'Navbar Color is required',
                      (v) => !/\s/.test(v) || 'No spaces allowed',
                      (v) => /^#/.test(v) || 'Must start with a hashtag (#)',
                    ]"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="navbarMenuItemColor"
                    label="Navbar Menu Item Color"
                    prepend-icon="mdi-palette"
                    placeholder="#FFFFFF"
                    :rules="[
                      (v) => !!v || 'Navbar Menu Item Color is required',
                      (v) => !/\s/.test(v) || 'No spaces allowed',
                      (v) => /^#/.test(v) || 'Must start with a hashtag (#)',
                    ]"
                  />
                </v-col>
              </v-row>
            </div>
          </v-container>
          <v-container>
            <div class="bg-grey-lighten-5 pa-3 rounded mb-2">
              <h3 style="font-size: 20px; font-weight: 700">Footer</h3>
              <p>Social links in footer.</p>
              <br />

              <v-text-field
                label="Instagram"
                v-model="footer.socialMedia.instagram"
                :rules="[
                  (v) =>
                    !v ||
                    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/.test(
                      v
                    ) ||
                    'Must be a valid URL',
                ]"
              />
              <v-text-field
                label="Twitter"
                v-model="footer.socialMedia.twitter"
                :rules="[
                  (v) =>
                    !v ||
                    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/.test(
                      v
                    ) ||
                    'Must be a valid URL',
                ]"
              />

              <v-text-field
                label="Facebook"
                v-model="footer.socialMedia.facebook"
                :rules="[
                  (v) =>
                    !v ||
                    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/.test(
                      v
                    ) ||
                    'Must be a valid URL',
                ]"
              />
            </div>
          </v-container>

          <v-container>
            <div class="bg-grey-lighten-5 pa-3 rounded mb-2">
              <h3 style="font-size: 20px; font-weight: 700">Lat/Long</h3>
              <p>Add the stores latitude & longitude.</p>
              <br />
              <v-text-field
                v-model="contact.latitude"
                label="Latitude"
                required
                :rules="[
                  (v) => !!v || 'Latitude is required',
                  (v) => !isNaN(v) || 'Latitude must be a number',
                  (v) =>
                    (v >= -90 && v <= 90) ||
                    'Latitude must be between -90 and 90',
                ]"
              />

              <v-text-field
                v-model="contact.longitude"
                label="Longitude"
                required
                :rules="[
                  (v) => !!v || 'Longitude is required',
                  (v) => !isNaN(v) || 'Longitude must be a number',
                  (v) =>
                    (v >= -180 && v <= 180) ||
                    'Longitude must be between -180 and 180',
                ]"
              />
            </div>
          </v-container>

          <v-container>
            <div class="bg-grey-lighten-5 pa-3 rounded mb-2">
              <h3 style="font-size: 20px; font-weight: 700">Images</h3>
              <p>
                Images used throughout the site.
                <span style="color: red">
                  <small>* Required</small>
                </span>
              </p>

              <br />

              <v-file-input
                v-model="logo"
                label="Upload Logo (Used in Navbar for both Desktop and Mobile)"
                accept="image/*"
                :rules="[(v) => !!v || 'Logo is required']"
              />
              <v-file-input
                v-model="favicon"
                label="Upload Favicon (Must be a PNG)"
                accept="image/png"
                :rules="[(v) => !!v || 'Favicon is required']"
              />
              <v-file-input
                v-model="errorPageImage"
                label="Upload Error Page Image (Used on 404 page)"
                accept="image/*"
                :rules="[(v) => !!v || 'Error Page Image is required']"
              />
              <v-file-input
                v-model="ctaImage"
                label="Upload CTA Image (Used on Home Page, below postcode search)"
                accept="image/*"
                :rules="[(v) => !!v || 'CTA Image is required']"
              />
              <v-file-input
                v-model="seoImage"
                label="Upload SEO Image (Used as meta image for all pages)"
                accept="image/*"
                :rules="[(v) => !!v || 'SEO Image is required']"
              />
              <v-file-input
                v-model="heroImage"
                label="Upload Hero Image (Used on Forgot, Register, Login & Reset pages as side/top hero image)"
                accept="image/*"
                :rules="[(v) => !!v || 'Hero Image is required']"
              />
            </div>
          </v-container>

          <v-container>
            <div class="bg-grey-lighten-5 pa-3 rounded mb-2">
              <h3 style="font-size: 20px; font-weight: 700">
                Single Store Mode
              </h3>
              <p>
                If the whitelabel is to be used in single store mode, you will
                need a slug & postcode.
              </p>
              <br />
              <v-checkbox
                value="singleStoreMode"
                label="Single Store Mode"
                @change="
                  () => {
                    singleStoreMode = !singleStoreMode;
                    if (singleStoreMode) extractPostcode();
                  }
                "
              />
              <v-text-field
                v-if="singleStoreMode"
                v-model="singleStore.slug"
                label="Single Store Slug"
                required
                :rules="[
                  (v) => !!v || 'Single Store Slug is required',
                  (v) =>
                    !v.includes('/') ||
                    'Single Store Slug must not contain slashes',
                ]"
              />
              <v-text-field
                v-if="singleStoreMode"
                v-model="singleStore.postcode"
                label="Single Store Postcode"
                required
                :rules="[(v) => !!v || 'Single Store Postcode is required']"
              />
            </div>
          </v-container>

          <v-container>
            <div class="bg-grey-lighten-5 pa-3 rounded mb-2">
              <h3 style="font-size: 20px; font-weight: 700">Strapi</h3>
              <p>
                API Information for submitting to Strapi.
                <span style="color: red">
                  <small>* Required</small>
                </span>
              </p>
              <br />
              <v-text-field
                label="Strapi URL (Include https:// without trailing slash / )"
                v-model="strapi.url"
                :rules="[
                  (v) => !!v || 'Strapi URL is required',
                  (v) =>
                    !v.endsWith('/') || 'URL should not have a trailing slash',
                ]"
              />

              <v-text-field
                label="Strapi Access Token"
                v-model="strapi.auth"
                :rules="[(v) => !!v || 'Strapi Access Token is required']"
              />
            </div>
          </v-container>

          <div
            style="padding: 10px; background: ghostwhite; border-radius: 10px"
          >
            <v-btn
              type="submit"
              color="primary"
              :loading="loading"
              :disabled="mainFormButtonDisabled"
              size="large"
            >
              Submit
            </v-btn>
            <span v-if="loading" class="ml-2">
              {{ mainProgressMessage }}
            </span>
            <span v-if="showFinishMessage" class="ml-2" style="font-size: 20px">
              Whitelabel Submission Complete! 🎉
            </span>
            <span v-if="errorMessage" class="ml-2" style="font-size: 20px">
              {{ errorMessage }} 🚨
            </span>
          </div>
        </v-form>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref, computed } from "vue";
import { ep } from "../constants/strapiUrls";
import { content } from "../constants/content";

export default {
  name: "WhiteLabelSetupView",
  setup() {
    const progressMessage = ref("");
    const mainProgressMessage = ref("");
    const errorMessage = ref("");
    const showFinishMessage = ref(false);
    const strapi = ref({
      url: "",
      auth: "",
    });
    const singleStoreMode = ref(false);
    const singleStore = ref({
      slug: "",
      postcode: "",
    });
    const gptData = ref({
      gptLoading: false,
      storeName: "",
      storeAddress: "",
      storeType: "",
      openingHours: "",
      contactPhoneNumber: "",
    });
    const showMainForm = ref(false);
    const loading = ref(false);
    const logo = ref(null);
    const favicon = ref(null);
    const navbarColor = ref("#FFFFFF");
    const navbarMenuItemColor = ref("#000000");
    const errorPageImage = ref(null);
    const heroImage = ref(null);
    const ctaImage = ref(null);
    const seoImage = ref(null);
    const brandingColors = ref({
      primary: "#1976D2", // Blue
      secondary: "#424242", // Grey
      success: "#4CAF50", // Green
      danger: "#F44336", // Red
      mainBg: "#FFFFFF", // White
      text: "#212121", // Near-black
      info: "#29B6F6", // Light blue
      highlight: "#FFCA28", // Amber
      warning: "#FF9800", // Orange
    });
    const footer = ref({
      banner: "",
      appStores: {
        ios: "",
        android: "",
      },
      paymentMethods: {
        visa: true,
        masterCard: true,
        applePay: false,
        googlePay: false,
        amex: false,
      },
      socialMedia: {
        instagram: "",
        twitter: "",
        facebook: "",
      },
      copyright: "Copyright Snappy Shopper",
      bgColor: "#FFFFFF",
      logo: {
        image: null,
        imageMobile: null,
        height: "",
        heightMobile: "",
      },
      hideOnStorePages: false,
      hideOnMobile: false,
      showPoweredby: true,
    });
    const home = ref({
      content: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
      blocks: [],
    });
    const contact = ref({
      title: "",
      subtitle: "",
      latitude: "",
      longitude: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
    });
    const faq = ref({
      title: "",
      subtitle: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
    });
    const forgotPassword = ref({
      title: "",
      subtitle: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
    });
    const login = ref({
      title: "",
      subtitle: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
    });
    const register = ref({
      title: "",
      subtitle: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
    });
    const resetPassword = ref({
      title: "",
      subtitle: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
    });
    const about = ref({
      title: "",
      subtitle: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
      about: "",
    });
    const terms = ref({
      title: "",
      subtitle: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
      content: "",
    });
    const privacyPolicy = ref({
      title: "",
      subtitle: "",
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
      content: "",
    });
    const apiKey = ref(
      "sk-proj-322lBRBrGABDqt8C6cQcT3BlbkFJeClkeMlvO9pPqFJRc0Ks"
    );

    const gptFormDisabled = computed(() => {
      return (
        !gptData.value.storeName ||
        !gptData.value.storeAddress ||
        !gptData.value.storeType ||
        !gptData.value.openingHours ||
        !gptData.value.contactPhoneNumber
      );
    });
    const mainFormButtonDisabled = computed(() => {
      return (
        !home.value.title ||
        !home.value.subtitle ||
        !home.value.content ||
        !home.value.seo.title ||
        !home.value.seo.description ||
        !home.value.seo.keywords ||
        !contact.value.title ||
        !contact.value.subtitle ||
        !contact.value.seo.title ||
        !contact.value.seo.description ||
        !contact.value.seo.keywords ||
        !contact.value.latitude ||
        !contact.value.longitude ||
        !faq.value.title ||
        !faq.value.subtitle ||
        !faq.value.seo.title ||
        !faq.value.seo.description ||
        !faq.value.seo.keywords ||
        !forgotPassword.value.title ||
        !forgotPassword.value.subtitle ||
        !forgotPassword.value.seo.title ||
        !forgotPassword.value.seo.description ||
        !forgotPassword.value.seo.keywords ||
        !login.value.title ||
        !login.value.subtitle ||
        !login.value.seo.title ||
        !login.value.seo.description ||
        !login.value.seo.keywords ||
        !register.value.title ||
        !register.value.subtitle ||
        !register.value.seo.title ||
        !register.value.seo.description ||
        !register.value.seo.keywords ||
        !resetPassword.value.title ||
        !resetPassword.value.subtitle ||
        !resetPassword.value.seo.title ||
        !resetPassword.value.seo.description ||
        !resetPassword.value.seo.keywords ||
        !about.value.title ||
        !about.value.subtitle ||
        !about.value.about ||
        !about.value.seo.title ||
        !about.value.seo.description ||
        !about.value.seo.keywords ||
        !terms.value.title ||
        !terms.value.subtitle ||
        !terms.value.seo.title ||
        !terms.value.seo.description ||
        !terms.value.seo.keywords ||
        !privacyPolicy.value.title ||
        !privacyPolicy.value.subtitle ||
        !privacyPolicy.value.seo.title ||
        !privacyPolicy.value.seo.description ||
        !privacyPolicy.value.seo.keywords ||
        !heroImage.value ||
        !logo.value ||
        !favicon.value ||
        !errorPageImage.value ||
        !ctaImage.value ||
        !seoImage.value ||
        (singleStoreMode.value &&
          (!singleStore.value.slug || !singleStore.value.postcode)) ||
        !strapi.value.url ||
        !strapi.value.auth
      );
    });

    const uploadMedia = async (file) => {
      try {
        const url = `${strapi.value.url}/api/upload`;
        const formData = new FormData();
        formData.append("files", file[0]);

        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${strapi.value.auth}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Upload failed with status: ${response.status}`);
        }

        const data = await response.json();
        return data[0].id;
      } catch (error) {
        loading.value = false;
        console.error("Failed to upload media:", error.message);
        return null;
      }
    };

    const submitForm = async () => {
      loading.value = true;
      errorMessage.value = "";
      showFinishMessage.value = false;

      try {
        mainProgressMessage.value = "Checking Strapi API connectivity... 🛠️";

        const strapiURL = strapi?.value?.url;
        const headers = {
          Authorization: `Bearer ${strapi.value.auth}`,
          "Content-Type": "application/json",
        };

        const testResponse = await fetch(`${strapiURL}/api/global`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            data: { testField: "Test Connection" },
          }),
        });

        if (!testResponse.ok) {
          throw new Error(
            "Failed to connect to Strapi API. Check Strapi Access Token/Strapi URL"
          );
        }

        mainProgressMessage.value = "Strapi API connection successful! 🎉";

        mainProgressMessage.value = "Uploading Logo to Strapi... 📸";
        const logoId = logo.value ? await uploadMedia(logo.value) : null;
        if (!logoId) throw new Error("Failed to upload logo");

        mainProgressMessage.value = "Uploading Favicon to Strapi... 📸";
        const faviconId = favicon.value
          ? await uploadMedia(favicon.value)
          : null;
        if (!faviconId) throw new Error("Failed to upload favicon");

        mainProgressMessage.value = "Uploading SEO Image to Strapi... 📸";
        const seoImageId = seoImage.value
          ? await uploadMedia(seoImage.value)
          : null;
        if (!seoImageId) throw new Error("Failed to upload SEO image");

        mainProgressMessage.value = "Uploading CTA Image to Strapi... 📸";
        const ctaImageId = ctaImage.value
          ? await uploadMedia(ctaImage.value)
          : null;
        if (!ctaImageId) throw new Error("Failed to upload CTA image");

        mainProgressMessage.value =
          "Uploading Error Page Image to Strapi... 📸";
        const errorPageImageId = errorPageImage.value
          ? await uploadMedia(errorPageImage.value)
          : null;
        if (!errorPageImageId)
          throw new Error("Failed to upload error page image");

        mainProgressMessage.value = "Uploading Hero Image to Strapi... 📸";
        const heroImageId = heroImage.value
          ? await uploadMedia(heroImage.value)
          : null;
        if (!heroImageId) throw new Error("Failed to upload hero image");

        let globalRequestData = {
          data: {
            companyName: gptData.value.storeName,
            brandingColors: brandingColors.value,
            logo: logoId,
            faviconImage: faviconId,
            errorPageImage: errorPageImageId,
            footer: footer.value,
            footerBlocks: [
              {
                __component: "footer-menu.footer-menu",
                heading: "About Us",
                color: null,
                hideOnMobile: false,
                menus: [
                  {
                    label: "About Us",
                    link: "about-us",
                    newWindow: false,
                  },
                  {
                    label: "Privacy Policy",
                    link: "privacy-policy",
                    newWindow: false,
                  },
                  {
                    label: "Terms & Conditions",
                    link: "terms-and-conditions",
                    newWindow: false,
                  },
                  {
                    label: "FAQ",
                    link: "faqs",
                    newWindow: false,
                  },
                  {
                    label: "Contact Us",
                    link: "contact",
                    newWindow: false,
                  },
                ],
              },
              {
                __component: "footer-content.footer-content",
                heading: "Delivery Information",
                content: `<p>${gptData.value.openingHours}</p>`,
                hideOnMobile: false,
              },
              {
                __component: "footer-content.footer-content",
                heading: "Contact Us",
                content: `<p>${gptData.value.contactPhoneNumber}, ${gptData.value.storeAddress}</p>`,
                hideOnMobile: false,
              },
            ],
            header: {
              hideStoreInfo: true,
              navbarDesktop: {
                logo: logoId,
                height: "80px",
                logoHeight: "40px",
                bgColor: brandingColors.value.primary,
              },
              navbarMobile: {
                logo: logoId,
                height: "80px",
                logoHeight: "40px",
                bgColor: brandingColors.value.primary,
              },
            },
          },
        };

        // Conditionally add singleStore data
        if (singleStoreMode.value) {
          globalRequestData.data.singleStore = {
            slug: singleStore.value.slug,
            postcode: singleStore.value.postcode,
          };
        }

        const globalRequest = await fetch(`${strapiURL}${ep.global}`, {
          method: "PUT",
          headers,
          body: JSON.stringify(globalRequestData),
        });

        const homeRequest = await fetch(`${strapiURL}${ep.home}`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            data: {
              seo: {
                title: home.value.seo.title,
                description: home.value.seo.description,
                keywords: home.value.seo.keywords,
                image: seoImageId,
              },
              blocks: [
                {
                  __component: "banner-block.banner-block",
                  title: "Local Store To Door",
                  subTitle: "From as little as 30 minutes.",
                  button: {
                    label: "Shop Now",
                    outlined: false,
                    newWindow: false,
                    color: brandingColors.value.secondary,
                    colorMobile: brandingColors.value.secondary,
                    textColor: brandingColors.value.primary,
                    textColorMobile: brandingColors.value.primary,
                  },
                  backgroundColor: brandingColors.value.primary,
                  align: "center",
                  logo: logoId,
                  backgroundImage: null,
                  searchErrorColor: "#000000",
                  searchErrorColorMobile: "#000000",
                  logo: null,
                },
                {
                  __component: "content-image-block.content-image-block",
                  content: `<p>${home.value.content}</p>`,
                  hideOnMobile: false,
                  image: ctaImageId,
                },
              ],
            },
          }),
        });

        const contactRequest = await fetch(`${strapiURL}${ep.contact}`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            data: {
              title: contact.value.title,
              subTitle: contact.value.subtitle,
              address: gptData.value.storeAddress,
              phoneNumber: gptData.value.contactPhoneNumber,
              latitude: contact.value.latitude,
              longitude: contact.value.longitude,
              seo: {
                title: contact.value.seo.title,
                description: contact.value.seo.description,
                keywords: contact.value.seo.keywords,
                image: seoImageId,
              },
            },
          }),
        });

        const faqRequest = await fetch(`${strapiURL}${ep.faq}`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            data: {
              title: faq.value.title,
              subTitle: faq.value.subtitle,
              seo: {
                title: faq.value.seo.title,
                description: faq.value.seo.description,
                keywords: faq.value.seo.keywords,
                image: seoImageId,
              },
              faqs: [
                {
                  answer:
                    "<p>We're sorry to hear this. Please get in contact with our store, our contact information is available within your order confirmation email.</p>",
                  question: "Something went wrong with my order, what do I do?",
                },
                {
                  answer:
                    "<p>First of all, we're sorry if your order has been rejected. Our store aims to accept as many orders as possible, however, stores may reject orders because products are out of stock or we cannot make the allocated delivery time.</p><p>If your order has been rejected, we'll let you know by email. Any payment made will be voided and should show up on your statement within 24 hours, however, some banks can take up to 10 days to refund the transaction.</p>",
                  question: "Why was my order rejected?",
                },
                {
                  answer:
                    "<p>As part of the transaction you will be charged a small service charge for the use of our service. There will be no partial refunds for this service charge in the event some items are unavailable or not accepted. In the event your order is cancelled or refunded in its entirety the service charge will also be refunded.</p>",
                  question: "Service Charge",
                },
              ],
            },
          }),
        });

        const forgotPasswordRequest = await fetch(
          `${strapiURL}${ep.forgotPassword}`,
          {
            method: "PUT",
            headers,
            body: JSON.stringify({
              data: {
                title: forgotPassword.value.title,
                subTitle: forgotPassword.value.subtitle,
                hero: {
                  id: heroImageId,
                },
                seo: {
                  title: forgotPassword.value.seo.title,
                  description: forgotPassword.value.seo.description,
                  keywords: forgotPassword.value.seo.keywords,
                  image: seoImageId,
                },
              },
            }),
          }
        );

        const loginRequest = await fetch(`${strapiURL}${ep.login}`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            data: {
              title: login.value.title,
              subTitle: login.value.subtitle,
              seo: {
                title: login.value.seo.title,
                description: login.value.seo.description,
                keywords: login.value.seo.keywords,
                image: seoImageId,
              },
              cta: {
                title: "Create an account",
              },
              hero: {
                id: heroImageId,
              },
            },
          }),
        });

        const registerRequest = await fetch(`${strapiURL}${ep.register}`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            data: {
              title: register.value.title,
              subTitle: register.value.subtitle,
              seo: {
                title: register.value.seo.title,
                description: register.value.seo.description,
                keywords: register.value.seo.keywords,
                image: seoImageId,
              },
              hero: {
                id: heroImageId,
              },
            },
          }),
        });

        const resetPasswordRequest = await fetch(
          `${strapiURL}${ep.resetPassword}`,
          {
            method: "PUT",
            headers,
            body: JSON.stringify({
              data: {
                title: resetPassword.value.title,
                subTitle: resetPassword.value.subtitle,
                seo: {
                  title: resetPassword.value.seo.title,
                  description: resetPassword.value.seo.description,
                  keywords: resetPassword.value.seo.keywords,
                  image: seoImageId,
                },
                hero: {
                  id: heroImageId,
                },
              },
            }),
          }
        );

        const aboutRequest = await fetch(`${strapiURL}${ep.page}`, {
          method: "POST",
          headers,
          body: JSON.stringify({
            data: {
              title: about.value.title,
              subTitle: about.value.subtitle,
              slug: "about-us",
              seo: {
                title: about.value.seo.title,
                description: about.value.seo.description,
                keywords: about.value.seo.keywords,
                image: seoImageId,
              },
              blocks: [
                {
                  __component: "content-block.content-block",
                  content: `<p>${about.value.about}</p>`,
                },
              ],
            },
          }),
        });

        const privacyPolicyRequest = await fetch(`${strapiURL}${ep.page}`, {
          method: "POST",
          headers,
          body: JSON.stringify({
            data: {
              title: privacyPolicy.value.title,
              subTitle: privacyPolicy.value.subtitle,
              slug: "privacy-policy",
              seo: {
                title: privacyPolicy.value.seo.title,
                description: privacyPolicy.value.seo.description,
                keywords: privacyPolicy.value.seo.keywords,
                image: seoImageId,
              },
              blocks: [
                {
                  __component: "content-block.content-block",
                  content: `<p>${content.privacy}</p>`,
                },
              ],
            },
          }),
        });

        const termsAndConditionsRequest = await fetch(
          `${strapiURL}${ep.page}`,
          {
            method: "POST",
            headers,
            body: JSON.stringify({
              data: {
                title: terms.value.title,
                subTitle: terms.value.subtitle,
                slug: "terms-and-conditions",
                seo: {
                  title: terms.value.seo.title,
                  description: terms.value.seo.description,
                  keywords: terms.value.seo.keywords,
                  image: seoImageId,
                },
                blocks: [
                  {
                    __component: "content-block.content-block",
                    content: `<p>${content.terms}</p>`,
                  },
                ],
              },
            }),
          }
        );

        const storeRequest = await fetch(`${strapiURL}${ep.store}`, {
          method: "PUT",
          headers,
          body: JSON.stringify({
            data: {
              categoryTitle: "Menu",
            },
          }),
        });

        mainProgressMessage.value = "Uploading Page Content to Strapi... 🔥";
        // Submit all requests
        await Promise.all([
          globalRequest,
          homeRequest,
          contactRequest,
          faqRequest,
          forgotPasswordRequest,
          loginRequest,
          registerRequest,
          resetPasswordRequest,
          aboutRequest,
          privacyPolicyRequest,
          termsAndConditionsRequest,
          storeRequest,
        ]);
        console.log("All requests completed successfully.");
        showFinishMessage.value = true;
      } catch (e) {
        loading.value = false;
        errorMessage.value = e;
        console.error(e);
      } finally {
        loading.value = false;
        mainProgressMessage.value = "";
      }
    };
    const submitGptForm = async () => {
      try {
        gptData.value.gptLoading = true;
        progressMessage.value = "Initializing...";
        console.log("Step 1: Initializing...");

        // Mapping from page names to data properties
        const pageDataMap = {
          Home: home.value,
          Contact: contact.value,
          FAQ: faq.value,
          "Forgot Password": forgotPassword.value,
          Login: login.value,
          Register: register.value,
          "Reset Password": resetPassword.value,
          About: about.value,
          "Privacy Policy": privacyPolicy.value,
          Terms: terms.value,
        };

        const generateSeoData = async (pageName) => {
          progressMessage.value = `Fetching ${pageName} Content... 🔜`;
          console.log(`Fetching SEO data for ${pageName}`);

          const messages = [
            {
              role: "system",
              content:
                "Generate SEO title, description, and keywords for a page.",
            },
            {
              role: "user",
              content: `Generate a Subtitle, SEO title, description, and keywords for the ${pageName} page of an online store named "${gptData.value.storeName}", when returning the description, take into account the Store type of: ${gptData.value.storeType}, and the opening hours of: ${gptData.value.openingHours}. Keep it brief and informative. The Subtitle should be short, and relevant, unless in the prior sentence, I said we are working on the 'Home' page, you MUST flesh that one out a bit more, making it a couple paragraphs. If the page name I have asked you to work on is 'About', we will also need a paragraph for About Us text. Return the data in the format: Title: "Title", Description: "Description", Keywords: "Keyword1, Keyword2, Keyword3", Subtitle: "Subtitle", About: "About Us Text".`,
            },
          ];

          const data = {
            messages: messages,
            max_tokens: 350,
            temperature: 0.7,
            model: "gpt-4",
          };

          try {
            const response = await fetch(
              "https://api.openai.com/v1/chat/completions",
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${apiKey.value}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );

            const responseData = await response.json();
            if (
              responseData &&
              responseData.choices &&
              responseData.choices.length > 0
            ) {
              const rawContent = responseData.choices[0].message.content.trim();
              const parsedData = rawContent.split("\n").reduce((acc, line) => {
                const [key, value] = line
                  .split(": ")
                  .map((part) => part.trim());
                if (key && value) {
                  acc[key.toLowerCase()] = value.replace(/^['"]+|['",]+$/g, "");
                }
                return acc;
              }, {});

              return parsedData;
            }
          } catch (error) {
            errorMessage.value = `Failed to fetch SEO data for ${pageName}: ${error.message}`;
            console.error(
              `Failed to fetch SEO data for ${pageName}:`,
              error.message
            );
          }
        };

        const generateHomeContent = async () => {
          progressMessage.value = "Fetching Home Content... 🔜";
          console.log("Fetching Home content");

          const messages = [
            {
              role: "system",
              content: "Generate content for the home page.",
            },
            {
              role: "user",
              content: `Generate content for the home page of an online store named "${gptData.value.storeName}". The store type is "${gptData.value.storeType}" and the opening hours are "${gptData.value.openingHours}". Make sure the content is engaging and informative, providing a warm welcome to customers. Emphasize the unique selling points of the store and create a sense of excitement about the products offered. Return the data completely immediately with no additional context. ENSURE the response contains at least 200 words. You should also use <p> tags to separate paragraphs.`,
            },
          ];

          const data = {
            messages: messages,
            max_tokens: 500,
            temperature: 0.8,
            model: "gpt-4",
          };

          try {
            const response = await fetch(
              "https://api.openai.com/v1/chat/completions",
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${apiKey.value}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );

            const responseData = await response.json();
            if (
              responseData &&
              responseData.choices &&
              responseData.choices.length > 0
            ) {
              const rawContent = responseData.choices[0].message.content.trim();
              home.value.content = `<h3 style="text-align:center;"><span>Welcome to</span></h3><h1 style="text-align:center;"><span><strong>${gptData.value.storeName}</strong></span></h1>${rawContent}`;
              console.log("Home content fetched successfully");
            }
          } catch (error) {
            errorMessage.value = `Failed to fetch Home content: ${error.message}`;
            console.error("Failed to fetch Home content:", error.message);
          }
        };

        const generateAboutContent = async () => {
          progressMessage.value = "Fetching About Content... 🔜";
          console.log("Fetching About content");

          const messages = [
            {
              role: "system",
              content: "Generate content for the about page.",
            },
            {
              role: "user",
              content: `Generate content for the about page of an online store named "${gptData.value.storeName}". The store type is "${gptData.value.storeType}" and the opening hours are "${gptData.value.openingHours}". Make sure the content is engaging and informative, providing a warm welcome to customers. Emphasize the unique selling points of the store and create a sense of excitement about the products offered. Return the data completely immediately with no additional context. ENSURE the response contains at least 200 words. You should also use <p> tags to separate paragraphs, and use <strong> tags to denote headings. Some example headings could be what we offer, our story, and our mission, a thank you, but these are just examples. If it suits, you can also use an <ul> to list some nice points you come up with. Take all of this as a suggestion, and feel free to be creative, no need to follow the order of the suggestions either.`,
            },
          ];

          const data = {
            messages: messages,
            max_tokens: 500,
            temperature: 0.8,
            model: "gpt-4",
          };

          try {
            const response = await fetch(
              "https://api.openai.com/v1/chat/completions",
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${apiKey.value}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );

            const responseData = await response.json();
            if (
              responseData &&
              responseData.choices &&
              responseData.choices.length > 0
            ) {
              const rawContent = responseData.choices[0].message.content.trim();
              about.value.about = `<h3 style="text-align:center;"><span>Welcome to</span></h3><h1 style="text-align:center;"><span><strong>${gptData.value.storeName}</strong></span></h1>${rawContent}`;
              console.log("About content fetched successfully");
            }
          } catch (error) {
            errorMessage.value = `Failed to fetch About content: ${error.message}`;
            console.error("Failed to fetch About content:", error.message);
          }
        };

        await generateHomeContent();
        await generateAboutContent();

        // Fetch SEO data for all other pages
        for (const pageName in pageDataMap) {
          console.log(`Starting SEO data fetch for ${pageName}`);
          const seoData = await generateSeoData(pageName);
          if (seoData) {
            pageDataMap[pageName].title = seoData.title || "";
            pageDataMap[pageName].subtitle = seoData.subtitle || "";
            pageDataMap[pageName].seo.title = seoData.title || "";
            pageDataMap[pageName].seo.description = seoData.description || "";
            pageDataMap[pageName].seo.keywords = seoData.keywords || "";
            console.log(`${pageName} data fetched successfully`);
          }
        }

        gptData.value.gptLoading = false;
        showMainForm.value = true;
        progressMessage.value = "";
        console.log("All data fetched successfully, showing main form");
      } catch (error) {
        errorMessage.value = `Failed to generate data: ${error.message}`;
        console.error("Failed to generate data:", error.message);
        gptData.value.gptLoading = false;
      }
    };

    const extractPostcode = async () => {
      singleStore.value.postcode = "Extracting postcode using AI... 🧠";

      const messages = [
        {
          role: "system",
          content: "Extract the postcode from an address.",
        },
        {
          role: "user",
          content: `Extract the postcode from the following address: "${gptData.value.storeAddress}". Return ONLY the postcode in your response.`,
        },
      ];

      const data = {
        messages: messages,
        max_tokens: 50,
        temperature: 0.2,
        model: "gpt-4",
      };

      try {
        const response = await fetch(
          "https://api.openai.com/v1/chat/completions",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${apiKey.value}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        const responseData = await response.json();
        if (
          responseData &&
          responseData.choices &&
          responseData.choices.length > 0
        ) {
          const postcode = responseData.choices[0].message.content.trim();
          singleStore.value.postcode = postcode;
        }
      } catch (error) {
        console.error("Failed to extract postcode:", error.message);
      }
    };
    return {
      progressMessage,
      mainProgressMessage,
      errorMessage,
      showFinishMessage,
      strapi,
      singleStoreMode,
      singleStore,
      gptData,
      showMainForm,
      loading,
      logo,
      favicon,
      navbarColor,
      navbarMenuItemColor,
      errorPageImage,
      heroImage,
      ctaImage,
      seoImage,
      brandingColors,
      footer,
      home,
      contact,
      faq,
      forgotPassword,
      login,
      register,
      resetPassword,
      about,
      terms,
      privacyPolicy,
      gptFormDisabled,
      mainFormButtonDisabled,
      submitForm,
      submitGptForm,
      extractPostcode,
    };
  },
};
</script>
